<template>
  <v-container fluid px-3 d-block py-3 ma-0 grid-list-lg>
    <div class="panelview">
      <v-toolbar dense flat color="primary" light class="mb-3">
        <v-toolbar-title class="text-uppercase">Optical systems</v-toolbar-title>
      </v-toolbar>
      <v-container fluid grid-list-lg pa-0>
        <v-layout row wrap>
          <v-flex xs12>
            <installations
              title="Installations"
              data-cy="installations-widget"
              :cameraPage="true"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="mapview">
      <div>
        <browser-alert />
      </div>
      <v-flex :style="previewContainerStyle">
        <v-btn
          fab
          light
          small
          :style="moreStyle"
          @click="nextPreviews"
          v-if="previewOverflow"
        >
          <v-icon>keyboard_arrow_right</v-icon>
        </v-btn>
        <v-flex
          class="preview"
          v-for="camera in cameraList"
          @click="setActiveCamera(camera)"
          :key="`camera-${camera.id}`"
        >
          <span :key="`camera-sp-${camera.id}`" class="cameraName">{{
            camera.name
          }}</span>
          <stream
            :key="`camera-st-${camera.id}`"
            :camera_id="camera.id"
            :camera_data="camera"
            :controls="false"
            :switchableStream="environment === 'ZenithAerotech'"
            style="cursor:pointer"
          />
        </v-flex>
      </v-flex>
      <v-flex xs12>
        <stream
          v-if="activeCamera"
          ref="stream"
          :key="`stream-${activeCamera.id}`"
          @ptz="CAMERA_CONTROL($event)"
          :camera_id="activeCamera.id"
          :camera_data="activeCamera"
          :detections="activeCameraDetections"
          :switchableStream="environment === 'ZenithAerotech'"
        />
      </v-flex>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { Installations } from '@/components/Widgets'
import { DetectionsMixin } from '@/components/Mixins'
import BrowserAlert from '@/components/Base/BrowserAlert.vue'

export default {
  mixins: [DetectionsMixin],
  data: () => ({
    indexStart: 0,
    previewContainerStyle: {
      position: 'absolute',
      right: '60px',
      left: '100px',
      zIndex: 2,
      paddingRight: 'calc(1% + 50px)'
    },
    moreStyle: {
      display: 'inline-flex',
      position: 'absolute',
      opacity: 0.4,
      top: 'calc(54% - 15px)',
      right: '1%'
    },
    nbPreviewsMax: 2
  }),
  components: { Installations, BrowserAlert },
  computed: {
    ...mapState('system', ['environment']),
    ...mapGetters('cameras', ['allCameras']),
    ...mapState('selection', ['activeCamera']),
    cameraList() {
      return [...this.allCameras]
        .filter(
          v =>
            v.id !==
            (this.activeCamera ? this.activeCamera.id : this.allCameras[0].id)
          // Prevents instant mount/destroy before activeCameraId is set
        )
        .slice(this.indexStart, this.indexStart + this.nbPreviewsMax)
        .reverse()
    },
    previewOverflow() {
      return this.allCameras.length > this.nbPreviewsMax + 1
    },
    activeCameraDetections() {
      return Object.fromEntries(
        Object.entries(this.cameraDetections || {}).filter(
          ([k, _]) => k.split(':')[0] == this.activeCamera.id
        )
      )
    }
  },
  methods: {
    ...mapActions('cameras', ['CAMERA_CONTROL']),
    ...mapActions('selection', ['setActiveCamera']),
    checkOverflow() {
      this.previewContainerStyle.paddingRight = this.previewOverflow
        ? 'calc(1% + 50px)'
        : '1%'
      this.moreStyle.display = this.previewOverflow ? 'inline-flex' : 'none'
    },
    nextPreviews() {
      this.indexStart += 1
      if (this.indexStart + this.nbPreviewsMax > this.allCameras.length - 1)
        this.indexStart = 0
    },
    focusStream() {
      this.$refs.stream.$el.focus()
    }
  },
  watch: {
    allCameras() {
      this.checkOverflow()
    }
  },
  mounted() {
    if (!this.activeCamera?.id) this.setActiveCamera(this.allCameras[0])
    this.checkOverflow()
    this.$bus.$on('SOCKET/CAMERA_DETECTIONS_CREATE', ({ message }) =>
      message.data.forEach(d => {
        this.$_updateCameraDetections(d)
      })
    )
  },
  beforeDestroy() {
    // make sure to clear timeout
    this.$_clearAllDetections()
    this.$bus.$off('SOCKET/CAMERA_DETECTIONS_CREATE')
  }
}
</script>
<style scoped>
.preview {
  position: relative;
  margin: 1% 1% 0px 0px;
  width: 15%;
  float: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cameraName {
  padding-left: 2px;
  opacity: 0.8;
  text-shadow: 1px 1px 0px black, -1px -1px 0px black, -1px 1px 0px black,
    1px -1px 0px black;
}
</style>
